const THRESHOLD = 116;
const SHOW_MORE_CONTENT_SELECTOR = 'js-content-show-more';
const SHOW_MORE_SHOW_BUTTON_SELECTOR = 'js-btn-show';
const NAV_LIST_SELECTOR = 'o-nav-list__wrap-list';
export const isElementLowerThanThreshold = (refererElement = null, threshold = THRESHOLD) => {
  try {
    if (refererElement === null) throw 'All params for hideElementMoreThresholdHeight are mandatory';
    const refererElementHeight = refererElement ? refererElement.scrollHeight : 0;
    return refererElementHeight <= threshold;
  } catch (e) {
    throw `error : ${e}`;
  }
};
export const hideElementMoreThresholdHeight = (id = null) => {
  try {
    if (id === null) throw 'id of component is mandatory';
    const component = document.getElementById(id);
    const refererElement = component.querySelector(`.${SHOW_MORE_CONTENT_SELECTOR}`);
    const elementsToHide = component.querySelectorAll(`.${SHOW_MORE_SHOW_BUTTON_SELECTOR}`);
    const wrapperList = component.querySelector(`.${NAV_LIST_SELECTOR}`);
    if (refererElement && isElementLowerThanThreshold(refererElement) && elementsToHide) {
      elementsToHide.forEach(e => {
        e.classList.add('kl-hidden');
      });
      wrapperList.classList.add('o-nav-list__wrap-list--no-showmore');
      refererElement.classList.add('o-nav-list__description--no-showmore');
    }
  } catch (e) {
    throw `error : ${e}`;
  }
};
export const disclosureToggleShowHide = (id = null) => {
  try {
    if (id === null) throw 'id of component is mandatory';
    const component = document.getElementById(id);
    const disclosureButtons = component.querySelectorAll(`.${SHOW_MORE_SHOW_BUTTON_SELECTOR}`);
    disclosureButtons.forEach(btn => {
      btn.addEventListener('click', e => {
        e.target.closest(`.${NAV_LIST_SELECTOR}`).classList.toggle(`${NAV_LIST_SELECTOR}--open`);
        const expanded = e.target.getAttribute('aria-expanded');
        e.target.setAttribute('aria-expanded', `${expanded === 'false' ? 'true' : 'false'}`);
      });
    });
  } catch (e) {
    throw `error : ${e}`;
  }
};