import { disclosureToggleShowHide, hideElementMoreThresholdHeight } from '../commons/elementThreshold.tools';
import SwiperSlider from 'integration-web-core--socle/js/assets/modules/_swiperSlider.class';
const MAIN_THEMATIC_SWIPER = 'js-thematic-swiper';
class MainThematicMesh extends lm.Composant {
  constructor(id) {
    super(id);
    const elemSliderMainThematic = document.querySelector(`.${MAIN_THEMATIC_SWIPER}`);
    if (elemSliderMainThematic) {
      const swiperMainThematicObject = {};
      swiperMainThematicObject.instance = new SwiperSlider(`.${MAIN_THEMATIC_SWIPER}`);
      delete swiperMainThematicObject.instance;
    }
    hideElementMoreThresholdHeight(id);
    disclosureToggleShowHide(id);
  }
}
lm.DOMReady(function () {
  const sectionElem = document.querySelectorAll('.component-categorymetameshingenrichment-mainthematic');
  for (let i = 0; i < sectionElem.length; i++) {
    const idElem = sectionElem[i].getAttribute('id');
    if (idElem) {
      const mainThematicMeshObject = {};
      mainThematicMeshObject.instance = new MainThematicMesh(idElem);
      delete mainThematicMeshObject.instance;
    }
  }
});